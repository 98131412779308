import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Button } from '@epcbuilder/lib/components';
import Layout from '@/components/Layout';

export default function NotFound() {
  return (
    <>
      <Helmet>
        <title>EPC Builder</title>
        <meta name="description" content="Page not found" />
      </Helmet>
      <Layout title="EPC Builder - Not Found">
        <div className="relative flex h-fit max-w-full justify-center p-2 sm:p-4">
          <div className="flex w-full max-w-full flex-col lg:max-w-5xl">
            <div className="my-8 flex flex-col items-center gap-4">
              <h3 id="not-found-message">Sorry, the page you're looking for doesn't exist.</h3>
              <Link to={'/'}>
                <Button widthFitContent={true} className="w-fit" id="return-to-home">
                  Back to Home
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
