import React from 'react';

const EpcProcess = () => {
  return (
    <>
      <div className="m-4">
        <div>
          <h1 className="text-primary m-4 text-center font-sans text-3xl font-black uppercase">
            Energy Assessment - What To Expect
          </h1>
          <p className="mb-4 text-center text-lg font-bold">
            The assessment will typically last 1 – 2 hours depending on the size of your property and will include:
          </p>

          <div className="justify-center sm:ml-[10%] sm:w-[80%] md:ml-[25%] md:w-1/2 lg:ml-[33%] lg:w-1/3">
            <ul className="list-disc space-y-4">
              <li>
                <b>Building Fabric:</b> The assessor examines the construction materials and the condition of walls,
                floors, roofs, windows, and doors.
              </li>
              <li>
                <b>Heating System:</b> Information on the type, age, and efficiency of boilers, radiators, underfloor
                heating, and thermostats is collected.
              </li>
              <li>
                <b>Insulation:</b> The assessor checks for insulation in walls, roofs, and floors. They will require
                access to the loft to assess if there is any insulation present and if so, what depth it is.
              </li>
              <li>
                <b>Hot Water System:</b> The type, efficiency, and controls of the hot water system are assessed.
              </li>
              <li>
                <b>Lighting:</b> The type and efficiency of lighting installed in the property are evaluated.
              </li>
            </ul>

            <hr className="bg-gradient m-4 ml-[-5%] h-1 w-[110%] border-0" />

            <p className="mb-4 text-center font-bold">During the process the Assessor will:</p>
            <ul className="list-disc space-y-4">
              <li>
                Take various measurements, such as room sizes, wall thickness, and window dimensions, this is to
                calculate heat loss.
              </li>
              <li>
                Record all details related to the building's energy use and efficiency. This includes the age and the
                the property, heating and cooling systems, insulation levels, and energy-saving features.
              </li>
              <li>Take photographs as part of the documentation process.</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default EpcProcess;
