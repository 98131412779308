import React from 'react';
import { GetStarted } from '../get-started';
import { InteractiveHouse } from '../interactive-house';

const HomeImprovements = () => {
  return (
    <>
      <div className="bg-light relative flex flex-col items-center gap-8 overflow-hidden px-4 py-8">
        <div className="absolute left-1/2 top-1/2 size-[500px] -translate-x-1/2 -translate-y-1/2" />
        <div className="z-10 flex w-full max-w-sm flex-col items-center justify-center lg:max-w-none">
          <h1 className="font-header text-dark text-center text-2xl font-extrabold uppercase lg:text-4xl xl:text-5xl">
            Home improvement recommendations
          </h1>
          <div className="via-primary mb-2 h-[1px] w-full bg-gradient-to-r from-transparent to-transparent" />
          <p className="text-dark font-header text-center text-xs font-semibold uppercase italic lg:text-sm xl:text-base">
            Our on-site assessment will ensure we only recommend improvements which are suitable for your property
          </p>
        </div>
        <div className="z-10">
          <InteractiveHouse />
        </div>
      </div>
      <div className="bg-light flex w-full flex-col items-center gap-2 p-2 lg:gap-4 lg:p-4">
        <h1 className="font-header text-base font-extrabold uppercase lg:text-3xl">Ready to get started?</h1>
        <div className="flex flex-row gap-2">
          <GetStarted inline={true} />
        </div>
      </div>
      <div className="bg-light flex w-full flex-col items-center gap-2 p-2 lg:gap-4 lg:p-4">
        <h1 className="font-header text-base font-extrabold uppercase lg:text-3xl">Have any questions?</h1>
        <div className="flex flex-row gap-2">
          <a
            href="tel:08000584140"
            className="font-header bg-primary text-light flex h-10 w-28 items-center justify-center rounded-[22px] px-4 text-sm uppercase shadow hover:opacity-80 lg:h-16 lg:w-56 lg:text-xl"
          >
            Call us
          </a>
          <a
            href="mailto:info@epcbuilder.com"
            className="font-header bg-primary text-light flex h-10 w-28 items-center justify-center rounded-[22px] px-4 text-sm uppercase shadow hover:opacity-80 lg:h-16 lg:w-56 lg:text-xl"
          >
            Email us
          </a>
        </div>
      </div>
    </>
  );
};

export default HomeImprovements;
