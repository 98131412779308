import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '@/components/Layout';
import { EpcProcess } from '@/components/what-to-expect';

const WhatToExpectPage = () => {
  return (
    <>
      <Helmet>
        <title>EPC Builder</title>
        <meta
          name="description"
          content="Improving your property's value, efficiency, and running costs with home upgrades"
        />
      </Helmet>
      <Layout title="EPC Builder Terms of Service">
        <EpcProcess />
      </Layout>
    </>
  );
};

export default WhatToExpectPage;
