import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { Accordion, Container } from '@epcbuilder/lib/components';
import Layout from '@/components/Layout';

type Questions = {
  question: string;
  answer: JSX.Element;
};

const faq: Questions[] = [
  {
    question: 'What is EPC Builder?',
    answer: (
      <p>
        EPC Builder is a service provided by the Improveasy Group. Trading since 2011, Improveasy are a home
        improvements installer, specialising in providing access to government and local authority grants, enabling
        customers to receive free or heavily subsidised home improvements that will reduce their energy bills.
      </p>
    ),
  },
  {
    question: 'What is an EPC?',
    answer: (
      <p>
        An Energy Performance Certificate{' '}
        <a href="https://www.gov.uk/find-energy-certificate" className="text-link">
          (EPC)
        </a>{' '}
        tells you on a scale of A - G how energy efficient your property is. The most efficient homes and those which
        should have the lowest fuel bills are band A, with G being the least efficient with the highest fuel bills.
      </p>
    ),
  },
  {
    question: 'How much does it cost to use EPC Builder?',
    answer: (
      <p>
        It doesn't cost anything to use the online portal, if the property isn't eligible for any grant funding and you
        want to proceed there will be a cost for the assessment and the installation
      </p>
    ),
  },
  {
    question: 'What does an on-site energy assessment consist of?',
    answer: (
      <p>
        A qualified retrofit assessor will visit the property to conduct an in-depth assessment of its current and
        potential energy efficiency. Anything affecting energy consumption or heat loss will be checked over. It could
        take up to two hours depending on the size of the property. For more information, please refer to our guide on{' '}
        <a href="https://epcbuilder.com/what-to-expect" className="text-link">
          what to expect
        </a>
        .
      </p>
    ),
  },
  {
    question: 'What is in a Property Improvement Plan?',
    answer: (
      <p>
        Following the on-site assessment our experienced team will analyse the findings and provide a detailed plan.
        This includes an overview of the property, energy efficiency recommendations, and a full breakdown of costs for
        each measure including any grants the property is eligible for.
      </p>
    ),
  },
  {
    question: 'Can I get a grant to install energy efficient home improvements?',
    answer: (
      <p>
        As part of our service, we will check if your property is eligible for any grants or funding. As well as being
        an established managing agent for the Ofgem backed ECO4 scheme, we have exclusive access to various local
        authority grants.
      </p>
    ),
  },
  {
    question: 'If my property is not eligible for a grant, are their options to spread the cost of the improvements?',
    answer: (
      <p>
        Yes, Improveasy have been a{' '}
        <a href="https://register.fca.org.uk/s/firm?id=001b000000rJhPfAAK" className="text-link">
          finance broker
        </a>{' '}
        since 2011, we offer a variety of finance products including both secured and unsecured loans from a panel of
        lenders. Please enquire for more information.
      </p>
    ),
  },
  {
    question: 'What measures can you install?',
    answer: (
      <p>
        We are an accredited installer of all ECO home upgrades. These include solar panels, air source heat pumps, loft
        insulation, room in roof insulation, internal wall insulation, external wall insulation, cavity wall insulation,
        condensing boilers, radiators, electric storage heaters, and ventilation.
      </p>
    ),
  },
  {
    question: 'How much can you reduce my energy bills?',
    answer: (
      <p>
        Energy efficient home upgrades will always contribute to lower energy costs. Every home is slightly different so
        it's difficult to predict exact reductions.
      </p>
    ),
  },
  {
    question: 'Do you have to be a homeowner to use EPC Builder?',
    answer: (
      <p>
        No! EPC Builder is for anybody, but if you're a tenant you will need the permission of your landlord or housing
        association to make any improvements.
      </p>
    ),
  },
];

const QuestionAnswer = ({ question, answer }: Questions) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div className="bg-dark rounded-xl">
      <Accordion
        onClick={() => setOpen(!open)}
        accordionOpen={open}
        accordionChildren={<div className="text-light p-4">{answer}</div>}
      >
        <div className="flex w-full flex-row items-center justify-between gap-4">
          <h1 className="font-header text-light ml-4 flex-1 text-xl">{question}</h1>
          <MdKeyboardArrowDown size={20} className="text-white" />
        </div>
      </Accordion>
    </div>
  );
};

const FAQ = () => {
  return (
    <>
      <Helmet>
        <title>EPC Builder - FAQ</title>
        <meta
          name="description"
          content="Improving your property's value, efficiency, and running costs with home upgrades"
        />
      </Helmet>
      <Layout title="EPC Builder FAQ">
        <div className="bg-primary flex h-48 w-full items-center justify-center">
          <h1 className="font-header text-light text-center text-2xl">FAQ</h1>
        </div>
        <Container>
          <div className="my-16 flex flex-col gap-8">
            {faq.map((qa) => (
              <QuestionAnswer key={qa.question} question={qa.question} answer={qa.answer} />
            ))}
          </div>
        </Container>
      </Layout>
    </>
  );
};

export default FAQ;
