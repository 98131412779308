import React, { Dispatch, SetStateAction } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Button, Loading, Select, TextInput } from '@epcbuilder/lib/components';
import usePropertyOwnerStatuses from '@epcbuilder/lib/hooks/usePropertyOwnerStatuses';
import { CreateAddress } from '@epcbuilder/lib/models/properties';
import { POSTCODE_REGEX } from '@epcbuilder/lib/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FormStage } from '@/models/generic';

const addressConfirmationSchema = yup.object().shape({
  addressLine1: yup.string().required('Address Line 1 must not be empty'),
  addressLine2: yup.string().optional(),
  city: yup.string().required('City must not be empty'),
  postcode: yup.string().required().matches(POSTCODE_REGEX, 'Postcode is not a valid postcode'),
  ownerStatusId: yup.number().required('Owner Status must be entered').min(1, 'Owner Status must not be empty'),
});

const AddressStage = ({
  address,
  handleAddressUpdate,
  setFormStage,
}: {
  address: CreateAddress;
  handleAddressUpdate: (address: CreateAddress) => void;
  setFormStage: Dispatch<SetStateAction<FormStage>>;
}) => {
  const { statusOptions, isLoading: statusOptionsLoading } = usePropertyOwnerStatuses();
  const defaultValues: CreateAddress = {
    addressLine1: address.addressLine1,
    addressLine2: address.addressLine2,
    city: address.city,
    postcode: address.postcode,
    ownerStatusId: address.ownerStatusId,
  };

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<CreateAddress>({
    defaultValues,
    resolver: yupResolver(addressConfirmationSchema),
    reValidateMode: 'onSubmit',
  });

  const onSubmit: SubmitHandler<CreateAddress> = async (data) => {
    handleAddressUpdate({
      addressLine1: data.addressLine1,
      addressLine2: data.addressLine2,
      city: data.city,
      postcode: data.postcode.trim(),
      ownerStatusId: data.ownerStatusId,
    });
  };

  if (isSubmitting || statusOptionsLoading || !statusOptions) {
    return <Loading />;
  }

  return (
    <>
      <h1>Stage 2 of 4 - Confirm your Details</h1>
      <p className="mt-4 text-base font-bold">Address</p>
      <form className="mt-4 flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          {...register('addressLine1')}
          id="addressLine1"
          name="addressLine1"
          title="The first line for your address"
          placeholder="Address Line 1"
          error={errors.addressLine1?.message}
        />
        <TextInput
          {...register('addressLine2')}
          id="addressLine2"
          name="addressLine2"
          title="The second line for your address"
          placeholder="Address Line 2"
          error={errors.addressLine2?.message}
        />
        <TextInput
          {...register('city')}
          id="city"
          name="city"
          title="Your city"
          placeholder="City"
          error={errors.city?.message}
        />
        <TextInput
          {...register('postcode')}
          id="postcode"
          name="postcode"
          title="Your postcode"
          placeholder="Postcode"
          error={errors.postcode?.message}
        />
        <p className="text-base font-bold">Owner Status</p>
        <Select
          control={control}
          id="ownerStatusId"
          name="ownerStatusId"
          title="Select owner status"
          placeholder="Select owner status"
          error={errors.ownerStatusId?.message}
          options={statusOptions}
        />
        <Button loading={isSubmitting} type="submit">
          Next
        </Button>
      </form>
      <button type="button" className="text-link mt-4 text-center" onClick={() => setFormStage(FormStage.POSTCODE)}>
        Or search again
      </button>
    </>
  );
};

export default AddressStage;
