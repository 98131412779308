import React, { useCallback, useEffect, useRef, useState } from 'react';
import { MdArrowRightAlt, MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { Button, Image } from '@epcbuilder/lib/components';
import { PerspectiveCamera } from '@react-three/drei';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { Model as AirSourceHeatPump } from './AirSourceHeatPump';
import { Model as Boiler } from './Boiler';
import { Model as CavityWallInsulation } from './CavityWallInsulation';
import { Model as ExternalWallInsulation } from './ExternalWallInsulation';
import { Model as House } from './House';
import { Model as InternalWallInsulation } from './InternalWallInsulation';
import { Model as LoftInsulation } from './LoftInsulation';
import { Model as RoomInRoofInsulation } from './RoomInRoofInsulation';
import { Model as SolarPanels } from './SolarPanels';
import { Model as UnderfloorInsulation } from './UnderfloorInsulation';

const HOUSE_SCALE = 0.4;

enum ImprovementPoints {
  AIR_SOURCE_HEAT_PUMPS = 0,
  HEATING_CONTROLS = 1,
  CAVITY_WALL_INSULATION = 2,
  INTERNAL_WALL_INSULATION = 3,
  EXTERNAL_WALL_INSULATION = 4,
  LOFT_INSULATION = 5,
  SOLAR_PANELS = 6,
  UNDERFLOOR_INSULATION = 7,
  ROOM_IN_ROOF_INSULATION = 8,
}

export const ImprovementPointsArray = Object.values(ImprovementPoints).filter(
  (value) => typeof value === 'number'
) as number[];

type ImprovementType = {
  id: number;
  label: string;
  description: string;
  image: string;
  detailId?: string;
};

const improvements: ImprovementType[] = [
  {
    id: ImprovementPoints.AIR_SOURCE_HEAT_PUMPS,
    label: 'Air source heat pumps',
    description:
      'Heat pumps use air outside the property to provide central heating and hot water. Replace your old boiler or inefficient electric heating with a heat pump today, or combine your boiler with a heat pump and solar panels for a hybrid system which automatically switches to whichever energy source is cheaper to run.',
    image: '/images/improvements/air_source_heat_pumps.png',
    detailId: 'air-source-heat-pumps',
  },
  {
    id: ImprovementPoints.HEATING_CONTROLS,
    label: 'Condensing boilers and heating controls',
    description:
      "Condensing boilers are the most energy efficient on the market. Not only do they heat homes faster, they lower energy bills, and reduce carbon emissions. Paired with smart heating controls, you can optimise your heating schedule and temperature settings, ensuring that your home is cosy when needed and saves energy when you're away.",
    image: '/images/improvements/heating_controls.png',
    detailId: 'boiler',
  },
  {
    id: ImprovementPoints.CAVITY_WALL_INSULATION,
    label: 'Cavity wall insulation',
    description:
      'Most homes built after 1920 have cavities, whereas older homes tend to have solid walls. There are many types of cavity wall insulation material for different property types, including mineral wool, bead, or foam. This install saves on average £395 a year on energy bills.',
    image: '/images/improvements/cavity_wall_insulation.png',
    detailId: 'cavity-wall-insulation',
  },
  {
    id: ImprovementPoints.INTERNAL_WALL_INSULATION,
    label: 'Internal wall insulation',
    description:
      'Solid walls lose twice as much heat compared to cavity walls, so it is worth insulating them! Installation involves the attachment of a framework of wooden battens (50mm deep) to the wall, and the space between the battens is filled with wool fibre insulation material. This is then plastered over. Both internal and external insulation saves, on average, £465 on your energy bills per year.',
    image: '/images/improvements/internal_wall_insulation.png',
    detailId: 'internal-wall-insulation',
  },
  {
    id: ImprovementPoints.EXTERNAL_WALL_INSULATION,
    label: 'External wall insulation',
    description:
      'External wall insulation involves fixing a layer of insulation material to the outside of your home. Not only does this keep warmth in, but it can improve the appearance of your home, adding to its value! Typically, this insulation will add about 100mm to the house. It can be made of expanded polystyrene, phenolic boards (plastic foam), or mineral wool. This is then covered over with render.',
    image: '/images/improvements/external_wall_insulation.png',
    // no extra detail for this option
  },
  {
    id: ImprovementPoints.LOFT_INSULATION,
    label: 'Loft insulation',
    description:
      "No matter how efficient your heating is, proper insulation is essential. Loft insulation helps keep your home warm in winter and cool in summer by adding layers of insulating material to your loft. It's quick to install and lasts for decades, improving energy efficiency all year round.",
    image: '/images/improvements/loft_insulation.png',
    detailId: 'loft-insulation',
  },
  {
    id: ImprovementPoints.SOLAR_PANELS,
    label: 'Solar panels',
    description:
      "Harness the power of the sun to generate clean and renewable energy with a solar panel array. The power generated by solar panels is not only free, but could also make money! You can sell excess energy your home hasn't used back to the grid. Solar panels save you, on average, about £400 a year.",
    image: '/images/improvements/solar_panels.png',
    detailId: 'solar-pv',
  },
  {
    id: ImprovementPoints.UNDERFLOOR_INSULATION,
    label: 'Underfloor insulation',
    description:
      'Underfloor insulation stops draughts coming up from the floor if your floor is suspended. This insulation is made of mineral wool or rigid foam. Netting will be installed to keep the insulation in place. If there is a basement or cellar, the ceiling will be boarded once the insulation is fitted. Underfloor insulation takes a day to install and lasts for 25 years.',
    image: '/images/improvements/underfloor_insulation.png',
    // no extra detail for this option
  },
  {
    id: ImprovementPoints.ROOM_IN_ROOF_INSULATION,
    label: 'Room in roof',
    description:
      'Room in roof insulation keeps your converted loft energy-efficient year-round. Unlike loft insulation laid on the floor, it’s fixed to the sloped ceilings and walls, helping your home stay warmer in winter and cooler in summer. Made from durable materials like mineral wool or foam, it offers long-lasting thermal protection and helps reduce energy bills.',
    image: '/images/improvements/loft_insulation.png',
    detailId: 'roof-insulation',
  },
];

const HouseGroup = ({ active }: { active: ImprovementPoints | null }) => {
  const groupRef = useRef<THREE.Group>(null);
  const [rotation, setRotation] = useState<number>(1);
  const [show, setShow] = useState<ImprovementPoints | null>(null);

  const { camera } = useThree();

  // Set initial camera polar angle
  camera.position.set(0, 3, 6);
  camera.lookAt(0, 0, -2.5);
  camera.rotation.order = 'YXZ';

  useEffect(() => {
    setShow(null);
    setTimeout(() => {
      setShow(active);
    }, 400);
  }, [active]);

  useEffect(() => {
    switch (active) {
      case ImprovementPoints.AIR_SOURCE_HEAT_PUMPS:
        setRotation(-1.3);
        break;
      case ImprovementPoints.HEATING_CONTROLS:
        setRotation(1);
        break;
      case ImprovementPoints.CAVITY_WALL_INSULATION:
        setRotation(-1.9);
        break;
      case ImprovementPoints.INTERNAL_WALL_INSULATION:
        setRotation(1);
        break;
      case ImprovementPoints.EXTERNAL_WALL_INSULATION:
        setRotation(3);
        break;
      case ImprovementPoints.LOFT_INSULATION:
        setRotation(1);
        break;
      case ImprovementPoints.SOLAR_PANELS:
        setRotation(3.5);
        break;
      case ImprovementPoints.UNDERFLOOR_INSULATION:
        setRotation(1);
        break;
      case ImprovementPoints.ROOM_IN_ROOF_INSULATION:
        setRotation(1);
        break;
      default:
        setRotation(1);
        break;
    }
  }, [active]);

  useFrame(() => {
    // Rotate around the y-axis
    if (groupRef.current) {
      const rotationIncrement = 0.06;

      if (groupRef.current.rotation.y < rotation) {
        groupRef.current.rotation.y += rotationIncrement;
        if (groupRef.current.rotation.y > rotation) {
          groupRef.current.rotation.y = rotation;
        }
      } else {
        groupRef.current.rotation.y -= rotationIncrement;
        if (groupRef.current.rotation.y < rotation) {
          groupRef.current.rotation.y = rotation;
        }
      }
    }
  });

  return (
    <group ref={groupRef}>
      <House scale={HOUSE_SCALE} />
      <AirSourceHeatPump active={show === ImprovementPoints.AIR_SOURCE_HEAT_PUMPS} scale={HOUSE_SCALE} />
      <Boiler active={show === ImprovementPoints.HEATING_CONTROLS} scale={HOUSE_SCALE} />
      <CavityWallInsulation active={show === ImprovementPoints.CAVITY_WALL_INSULATION} scale={HOUSE_SCALE} />
      <ExternalWallInsulation active={show === ImprovementPoints.EXTERNAL_WALL_INSULATION} scale={HOUSE_SCALE} />
      <InternalWallInsulation active={show === ImprovementPoints.INTERNAL_WALL_INSULATION} scale={HOUSE_SCALE} />
      <LoftInsulation active={show === ImprovementPoints.LOFT_INSULATION} scale={HOUSE_SCALE} />
      <SolarPanels active={show === ImprovementPoints.SOLAR_PANELS} scale={HOUSE_SCALE} />
      <UnderfloorInsulation active={show === ImprovementPoints.UNDERFLOOR_INSULATION} scale={HOUSE_SCALE} />
      <RoomInRoofInsulation active={show === ImprovementPoints.ROOM_IN_ROOF_INSULATION} scale={HOUSE_SCALE} />
    </group>
  );
};

const InteractiveHouse = () => {
  const [mounted, setMounted] = useState<boolean>(false);
  const [active, setActive] = useState<ImprovementPoints | null>(null);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const intervalSkipCount = useRef<number>(0);
  const intervalMilliseconds = 10000;

  const goToNextImprovement = useCallback(() => {
    const currentIndex = active !== null ? ImprovementPointsArray.indexOf(active) : ImprovementPointsArray.length;
    const prevIndex = (currentIndex - 1 + ImprovementPointsArray.length) % ImprovementPointsArray.length;
    setActive(ImprovementPointsArray[prevIndex]);
  }, [active]);

  const goToPrevImprovement = () => {
    const currentIndex = active !== null ? ImprovementPointsArray.indexOf(active) : -1;
    const nextIndex = (currentIndex + 1) % ImprovementPointsArray.length;
    setActive(ImprovementPointsArray[nextIndex]);
  };

  const activeImprovement = improvements.find((improvement) => improvement.id === active);

  useEffect(() => {
    setMounted(true);

    const intervalId = setInterval(() => {
      if (intervalSkipCount.current === 0) {
        goToNextImprovement();
      } else {
        intervalSkipCount.current--;
      }
    }, intervalMilliseconds);

    return () => {
      clearInterval(intervalId);
    };
  }, [goToNextImprovement]);

  useEffect(() => {
    if (mounted) {
      const handleMediaChange = (event: MediaQueryListEvent) => {
        setIsMobile(event.matches);
      };

      const mediaQueryList = window.matchMedia('(max-width: 1023px)');
      mediaQueryList.addEventListener('change', handleMediaChange);

      setIsMobile(mediaQueryList.matches);
      return () => {
        mediaQueryList.removeEventListener('change', handleMediaChange);
      };
    }
  }, [mounted]);

  if (!mounted) return <></>;

  return (
    <div className="xl:justify-space-between flex max-w-sm flex-col items-center gap-4 px-4 lg:max-w-none lg:flex-row">
      <div className="flex w-full flex-col items-center justify-center">
        {active !== null && activeImprovement ? (
          <div className="flex flex-col justify-center gap-4 lg:h-[532px] lg:w-[455px] xl:w-[512px]">
            <div className="flex flex-row gap-4 lg:flex-col">
              <div className="relative h-32 w-52 lg:h-[220px] lg:w-[360px] xl:h-[240px] xl:w-[400px]">
                <Image src={activeImprovement?.image} alt={activeImprovement?.label} className="object-contain" />
              </div>
              {activeImprovement?.detailId ? (
                <h1 className="font-header text-dark flex w-24 flex-col items-center gap-2 text-sm font-semibold uppercase lg:w-full lg:flex-row lg:text-3xl lg:font-black">
                  <span className="grow">{activeImprovement?.label}</span>
                  <Link to={`/install-measures#${activeImprovement.detailId}`}>
                    <Button
                      renderChildrenOutsideParagraph={true}
                      className="font-header bg-light border-primary dark:bg-primary text-primary dark:text-light hover:bg-primary-lighter hover:border-primary-lighter dark:hover:bg-primary-dark dark:active:bg-dark-lighter active:bg-primary active:border-primary active:text-dark-dark disabled:text-neutral-darker disabled:bg-neutral-lighter disabled:border-neutral-darkest dark:disabled:text-neutral-darktext  dark:disabled:bg-dark-lightest flex h-8 w-full min-w-fit items-center justify-center rounded-[12px] border-2 px-3 text-xs uppercase shadow hover:opacity-80 lg:h-10 lg:rounded-[30px] lg:text-base dark:border-none"
                    >
                      <p className="lg:w-max">Read More</p>
                      <MdArrowRightAlt size={32} />
                    </Button>
                  </Link>
                </h1>
              ) : (
                <h1 className="font-header text-dark w-24 text-sm font-semibold uppercase lg:w-full lg:text-3xl lg:font-black">
                  {activeImprovement?.label}
                </h1>
              )}
            </div>
            <p className="text-dark lg:text-m text-s h-auto font-normal">{activeImprovement?.description}</p>
          </div>
        ) : (
          <div className="flex h-[304px] flex-col items-center justify-center gap-8 lg:h-[532px] lg:w-[455px] lg:pb-8 xl:w-[512px]">
            <p className="text-dark w-52 text-center font-sans text-sm lg:w-96 lg:text-left lg:text-2xl">
              Transform your property with our improvement options.
            </p>
            <p className="text-dark w-52 text-center font-sans text-sm lg:w-96 lg:text-left lg:text-2xl">
              Look through the home upgrades of this house to see how it boosts energy efficiency!
            </p>
          </div>
        )}
      </div>
      <div className="flex flex-col items-center gap-8 lg:flex-col-reverse lg:gap-0">
        <div className="text-dark flex flex-row items-center">
          <button
            type="button"
            className="cursor-pointer p-2"
            onClick={() => {
              intervalSkipCount.current = 3;
              goToNextImprovement();
            }}
          >
            <MdKeyboardArrowLeft size={isMobile ? 40 : 60} />
          </button>
          <p className="font-header w-48 text-center text-sm font-black uppercase lg:text-lg">
            {activeImprovement?.label || 'Tap the arrows!'}
          </p>
          <button
            type="button"
            className="cursor-pointer p-2"
            onClick={() => {
              intervalSkipCount.current = 3;
              goToPrevImprovement();
            }}
          >
            <MdKeyboardArrowRight size={isMobile ? 40 : 60} />
          </button>
        </div>
        <div className="relative -mt-4 h-[280px] w-full lg:h-[400px] lg:w-[500px] xl:h-[500px] xl:w-[600px]">
          <Canvas>
            <PerspectiveCamera makeDefault />
            {/* eslint-disable-next-line react/no-unknown-property */}
            <ambientLight intensity={1.5} />
            {/* eslint-disable-next-line react/no-unknown-property */}
            <pointLight position={[0, 5, 0]} intensity={50} />
            <HouseGroup active={active} />
          </Canvas>
        </div>
      </div>
    </div>
  );
};

export default InteractiveHouse;
