import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from '@epcbuilder/lib/components';
import { Address, AddressResult, CreateAddress, PostcodeSearch } from '@epcbuilder/lib/models/properties';
import { FormStage } from '@/models/generic';
import AddressStage from './AddressStage';
import EpcStage from './EpcStage';
import PostcodeStage from './PostcodeStage';
import UserStage from './UserStage';

const RegisterModal = ({
  onClose,
  postcode: initialPostcode,
  initialResults,
  updateInitialResults,
}: {
  onClose: () => void;
  postcode?: string;
  initialResults?: AddressResult[];
  updateInitialResults: (search: PostcodeSearch) => void;
}) => {
  const [formStage, setFormStage] = useState<FormStage>(FormStage.POSTCODE);
  const [address, setAddress] = useState<CreateAddress>({} as CreateAddress);

  const [postcode, setPostcode] = useState<string>(initialPostcode || '');

  useEffect(() => {
    updateInitialResults({ postcode: postcode });
  }, [postcode, updateInitialResults]);

  const handleAddressFromPostcode = useCallback((result: Address) => {
    setAddress({
      addressLine1: result.line1,
      addressLine2: result.line2,
      city: result.city,
      postcode: result.postalCode,
    } as CreateAddress);
    setFormStage(FormStage.ADDRESS);
  }, []);

  const handleAddressUpdate = useCallback((address: CreateAddress) => {
    setAddress(address);
    setFormStage(FormStage.EPC);
  }, []);

  const handleBypassEpc = useCallback(() => {
    setAddress({ ...address, bypassEPC: true });
  }, [address]);

  useEffect(() => {
    const element = document.getElementById('modal');
    if (element) {
      element.scrollTop = 0;
    }
  }, [formStage]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal onClose={onClose}>
      {formStage === FormStage.POSTCODE && (
        <PostcodeStage
          postcode={postcode}
          setPostcode={setPostcode}
          initialResults={initialResults || []}
          handleAddressFromPostcode={handleAddressFromPostcode}
          setFormStage={setFormStage}
        />
      )}
      {formStage === FormStage.ADDRESS && (
        <AddressStage address={address} handleAddressUpdate={handleAddressUpdate} setFormStage={setFormStage} />
      )}
      {formStage === FormStage.EPC && (
        <EpcStage address={address} handleBypassEpc={handleBypassEpc} setFormStage={setFormStage} />
      )}
      {formStage === FormStage.USER && <UserStage onClose={onClose} address={address} setFormStage={setFormStage} />}
    </Modal>
  );
};

export default RegisterModal;
