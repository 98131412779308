import React from 'react';
import { Helmet } from 'react-helmet';
import { Container } from '@epcbuilder/lib/components';
import Layout from '@/components/Layout';

const Privacy = () => {
  return (
    <>
      <Helmet>
        <title>EPC Builder - Privacy Policy</title>
        <meta
          name="description"
          content="Improving your property's value, efficiency, and running costs with home upgrades"
        />
      </Helmet>
      <Layout title="EPC Builder Privacy Policy">
        <div className="bg-primary flex h-48 w-full items-center justify-center">
          <h1 className="text-light text-center text-2xl">Privacy Policy</h1>
        </div>
        <Container>
          <div className="my-8 flex flex-col gap-4">
            <h2>About Us</h2>
            <p>
              <b>EPC Builder is a trading style of Improveasy Ltd.</b> Our registered company number is 07807352 and our
              registered address is Station House, Stamford New Road, Altrincham, Cheshire, WA14 1EP. Tel: 0800 024
              8505. We are registered with the Office of the Information Commissioner in the UK registration number
              Z3542991.
            </p>
            <p>
              We provide a range of finance solutions for home improvement and energy efficiency measures, and we are
              also a competent and qualified Green Deal Provider that is authorised to access ECO funding where we act
              as a managing agent for the installations of energy efficiency measures to the highest standards across
              United Kingdom through a network of contracted installers.
            </p>
            <h2>Privacy Notice</h2>
            <p>
              <b>Improveasy Ltd</b> is committed to protecting the privacy of your personal information. Our company is
              registered with the Information Commissioners Office (ICO), in order to make transparent our data handling
              practices, and complies with the UK General Data Protection Regulations (GDPR) and the Data Protection Act
              2018.
            </p>
            <p>
              Good information handling makes good business sense. By doing this we believe we will enhance our
              business's reputation, increase customer and employee confidence, and by making sure personal information
              is accurate, relevant, and safe, save both time and money.
            </p>
            <p>
              Our organisation act as a Data Controller as we keep or process information about living people and must
              comply with certain important rules about how we collect and use personal information.
            </p>
            <h2>Lawful Basis for Processing</h2>
            <p>
              Our firm must have a valid lawful basis in order to process a person's data and be able to demonstrate the
              processing of data is “necessary” in order to provide our products and services. There are six lawful
              bases and our organisation process on the basis of the following:
            </p>
            <p>
              <b>CONTRACT</b> - We may have to process information to fulfil our contractual obligations to provide a
              quotation, or enter into a finance agreement, or provide a warranty. In this instance the processing is
              necessary for the performance of the contract.
              <br />
              <b>LEGAL OBLIGATION</b> - We may have to check data against the HMT Sanctions List which is a legal
              obligation or process information for health and safety reasons. In this instance Health and Safety is
              legislation.
              <br />
              <b>LEGITIMATE INTERESTS</b> - In many instances the processing is necessary, for example to create a
              Retrofit Assessment, Energy Performance Certificate, act as a credit intermediary in order to arrange a
              Finance Plan, access or check ECO Funding eligibility, carry out a Customer Survey Report, technical
              monitoring, provide other marketing information that may benefit a customer providing they agree to this.
              <br />
              <b>CONSENT</b> - We ask person's to positively opt in if they want to receive further information on an
              alternative product or service that we market separately.
            </p>
            <p>
              We process special category data where this is necessary. For example, we have a number of contracts with
              Utilities where Special Data is processed as part of the Government Energy Company Obligation scheme where
              health information (Benefit entitlement payment) is an essential part of scheme eligibility which we have
              to evidence and process.
            </p>
            <h2>Collection of Information - Your consent</h2>
            <p>
              We may collect personal information from you if you provide it voluntarily. If you do provide personal
              information to use, we will assume that you have read this Privacy Notice and have consented to us using
              your personal information in the ways described in this Policy and at the point where you give us your
              personal information. If, after providing us with personal information, you later decide that you do not
              want us to use it for particular purposes, then please write to us at the address shown.
            </p>
            <h2>Collection of your Information</h2>
            <p>
              We may collect and process the following data:
              <ul className="ml-8">
                <li className="list-disc">Information you provide when buying our products or services.</li>
                <li className="list-disc">Information you provide to us by filling in our forms.</li>
                <li className="list-disc">
                  Information collected through correspondence with our Sales and/or Customer Relations teams.
                </li>
                <li className="list-disc">Information you provide to us through the recruitment process.</li>
                <li className="list-disc">Information you provide to us in order to register for alerts.</li>
                <li className="list-disc">Social Media interaction.</li>
                <li className="list-disc">
                  Statistical data about your browsing actions and patterns for the administration of your application
                  for employment.
                </li>
                <li className="list-disc">
                  We may monitor or record any communications between you and us including telephone calls. We will use
                  these recordings to check your instructions to us, to analyse, assess and improve our services to
                  customers, complaints handling, and for training and quality purposes.
                </li>
                <li className="list-disc">
                  We may require you to submit personally identifiable information in order for you to make use of our
                  services. You confirm that any information you enter or provide will be true. We will only request and
                  collect information which is necessary or reasonable in order to provide you with your requested
                  services and to improve the services that we provide. It will not be a requirement to provide any
                  additional information which is not needed to provide the services.
                </li>
                <li>Credit or debit card information when making purchases via this method;</li>
                <li className="list-disc">
                  Profile information and insight from organisations that already hold information on you, such as
                  credit reference agencies and “customer insight companies.” They give us their views on your
                  household, your status, as well as your possible preferences and behaviours.
                </li>
              </ul>
            </p>
            <h2>Reasons for Collection of your Information and how we use your information</h2>
            <p>
              In the course of our dealing with you we may collect and process certain information about you, including
              your name, date of birth, address, contact details (including your email address and contact telephone
              number), employment details, medical history, payment details (where applicable), any benefits you receive
              or are entitled to (including disability benefits) (where applicable), and other information about you and
              your property in respect of which services and products may be provided. Your personal information may be
              used by us, our employees, lenders, contractors, or agents to:
            </p>
            <ul className="ml-8">
              <li className="list-disc">Identify you during any communication between you and us.</li>
              <li className="list-disc">Provide you with our websites, mobile apps, and Wi-Fi networks.</li>
              <li className="list-disc">
                Assess eligibility for services and products (whether provided by us or on our behalf).
              </li>
              <li className="list-disc">Manage your Consumer Credit Agreement.</li>
              <li className="list-disc">Carry out regulatory checks and meet our obligations to our regulators.</li>
              <li className="list-disc">Cross reference and screen your data against the HMT Sanctions List.</li>
              <li className="list-disc">
                Communicate with you to arrange the provision of such services and products.
              </li>
              <li className="list-disc">Administer and provide such services and products.</li>
              <li className="list-disc">Detect and prevent loss, fraud,and other criminal activity.</li>
              <li className="list-disc">Carry out credit reference checks through associated third parties.</li>
              <li className="list-disc">
                Carry out market research and to help us review, develop and improve the services and products we offer;
                and understand you better and, in particular, your habits, where you are from time to time, your
                personal circumstances and those of your family or household, and the things you may like, dislike and
                be interested in. If we hold accurate information about you, we are better placed to send you tailored
                offers, news and information that we think you may be interested in.
              </li>
              <li className="list-disc">
                Carry out DBS Checks and processing data about criminal convictions, criminal offences or related
                security measures as required, where persons acting in a sales position for or on behalf of our firm and
                where visiting a consumer in their home. No register of criminal convictions would be kept.
              </li>
              <li className="list-disc">
                Contact you (in accordance with your preferences), by post, telephone, SMS, email, and other electronic
                means with information about products, services, promotions, and offers that may be of interest to you.
              </li>
              <li className="list-disc">
                Keep legal certificates and work records relating to the services we have provided to you including
                details relating to:
                <ul className="ml-8">
                  <li>Warranty and guarantee information.</li>
                  <li>Workmanship guarantees on work done by us or our contracted partners.</li>
                  <li>Insurance Backed Guarantees.</li>
                  <li>Installation Certificates to include installing, commissioning and servicing.</li>
                  <li>
                    Records of any Legal or statutory notices we may have issued or supplied to include Gas Safe
                    Certificates, Building Regulations Notifications, Warning Notices, At Risk, Not to Current Standard
                    labelling by us or our contracted partners.
                  </li>
                  <li>
                    Any information which we consider may be required by the Health and Safety notice, product recall or
                    modification.
                  </li>
                  <li>
                    Industry Body Documentation and installation records to include Gas Safe, FENSA and OFTEC Audit
                    Bodies such as UKAS Accreditation Bodies for PAS:2030.
                  </li>
                  <li>
                    Government Bodies or appointed agencies such as Gemserv, TrustMark, Assessor or Installer
                    Organisations.
                  </li>
                  <li>
                    Records of Advice given to include Energy Performance Certificates (EPC’s) which remain legally
                    valid for 10 years after generation and may influence decisions to install or buy certain equipment.
                  </li>
                  <li>Occupancy Assessments and Retrofit Assessments.</li>
                  <li>
                    Any data we consider may be required by any Government Body such as OFGEM who retain the right of
                    Audit on schemes they administer on behalf of the government for at least six years after
                    installation.
                  </li>
                  <li>
                    Utilities such as those we provide services to in order to enable them to discharge their Energy
                    Company Obligation.
                  </li>
                  <li>
                    Domestic customers and their heirs and successors in title who may ask for copy of records of works
                    done at a property to enable property sales.
                  </li>
                  <li>
                    Waste and Environmental records and evidence which may be required by the Environmental Agency
                    relating to our Waste Carrier Licence and Disposal records.
                  </li>
                </ul>
              </li>
            </ul>
            <p>
              In the event that we sell or buy any business or assets, we may disclose personal information held by us
              to the prospective seller or buyer of such business or assets. If we or substantially all of our assets
              are acquired by a third party, personal information held by us will be one of the transferred assets.
            </p>
            <p>
              Your personal information may also be used by us, our employees,or agents if we are under a duty to
              disclose or share your personal information in order to comply with any legal obligation, or in order to
              enforce any agreement we have with or otherwise concerning you, or to protect our rights, property or
              safety or those of our customers, employees or other third parties.
            </p>
            <h2>With whom do we share your personal information?</h2>
            <p>
              We cannot run our business or provide many of the services and benefits you expect to receive without
              involving other people and businesses. We only share information in accordance with the UK General Data
              Protection Regulation (GDPR) and Data Protection Act 2018. We share the information we collect with third
              parties such as:
              <ul className="ml-8">
                <li className="list-disc">The Financial Conduct Authority (FCA).</li>
                <li className="list-disc">Green Deal Oversight and Registration Body.</li>
                <li className="list-disc">
                  A finance lender (where applying for a finance option where we act as a credit broker).
                </li>
                <li className="list-disc">Retrofit Assessors.</li>
                <li className="list-disc">Retrofit Coordinators.</li>
                <li className="list-disc">Other Installers.</li>
                <li className="list-disc">
                  Appointed Representatives and Introducer Appointed Representatives where we act as their Principal.
                </li>
                <li className="list-disc">
                  Energy Suppliers. Please note under ECO we may have to take photographs in the home to evidence the
                  installation of a certain measure that is eligible under ECO and so images and sometimes persons may
                  be captured and transferred to third parties as part of our obligation.
                </li>
                <li className="list-disc">
                  Credit reference agencies (who may check the information against other databases, public or private to
                  which they have access), or fraud prevention agencies. This information may come from your
                  interactions with us or them through applying for a finance product; or ascertain from the way in
                  which the loan is administered and managed, for example, information relating to the payments which
                  are made.
                </li>
                <li className="list-disc">Debt Collection Agencies.</li>
                <li className="list-disc">Fraud Prevention Agencies.</li>
                <li className="list-disc">
                  Other people and businesses who help us provide our websites, mobile apps, Wi FI networks and related
                  services to you, for example, information technology companies who design and host our website,
                  independent software firms that provide services to help our ECO submissions, payment services
                  companies who enable you to use credit or payment cards with us, and data insight specialists.
                </li>
                <li className="list-disc">
                  Any new business partners we may have over time, for example if we enter into a joint venture,
                  reorganisation, business merger or sale of part of our business, the other party may receive some or
                  all of your information.
                </li>
                <li className="list-disc">
                  Our professional advisors for example our lawyers, compliance officers, insurers, and insurance
                  brokers when they need it to provide advice or help us obtain insurance.
                </li>
                <li className="list-disc">
                  Other people who make a subject access request to us, where we are allowed to do so by law.
                </li>
                <li className="list-disc">Third parties involved in the handling of a complaint.</li>
              </ul>
            </p>
            <p>
              In connection with the above purposes, your personal information may be transferred to, or otherwise
              processed by third party service providers acting on our behalf, our agents and law enforcement
              authorities (including the police).
            </p>
            <h2>Access to Information</h2>
            <p>
              The UK GDPR gives you the right to access information held about you. You have the right to ask for a copy
              of the personal information held about you. You also have the right to ask for inaccuracies in information
              be corrected. Any access request is not subject to a fee unless the requests are unreasonable in which
              case a fee may be charged and will be disclosed at the time of request. A copy of the information held
              about you by us can be requested by writing to us at the address shown.
            </p>
            <h2>Transfer of Information Abroad</h2>
            <p>We will not transfer your personal information outside the UK.</p>
            <h2>Change of Policy</h2>
            <p>
              We may occasionally change the Privacy Notice to reflect customer and company feedback. Any changes will
              be shown on this page.
            </p>
            <h2>Breach Notification</h2>
            <p>
              Our business has the effective processes to identify, report, manage and resolve any personal data
              breaches.
            </p>
            <h2>Dealing with Data Protection Complaints</h2>
            <p>
              We aim to comply fully with our obligations under the UK General Data Protection Regulations and Data
              Protection Act 2018. If a customer has any questions or concerns regarding our company’s management of
              personal data including their right to access data about themselves, then they should contact Austin
              Barcley, the Managing Director, who is responsible for ensuring our company is compliant with data
              protection and is the nominated data protection lead or Data Protection Officer (DPO).
            </p>
            <p>
              If our company holds inaccurate information, then the customer should write to our firm at the address
              shown providing the director with any evidence to show what the information should say keeping copies of
              the correspondence. If after a reasonable amount of time (28 days is recommended) the information has not
              been corrected, then the customer can make a complaint.
            </p>
            <p>There are two courses of action:</p>
            <ol className="ml-8">
              <li className="list-decimal">Contact the director to process the complaint.</li>
              <li className="list-decimal">
                If the customer is still dissatisfied, they can go directly to the Information Commissioner, the
                independent body that oversees data protection and the GDPR. They can be contacted on 0303123 1113 or
                their website is{' '}
                <a className="text-link" href="http://www.ico.org.uk/">
                  www.ico.org.uk
                </a>
                .
              </li>
            </ol>
            <h2>Security</h2>
            <p>
              We are committed to ensuring that your information is secure. In order to prevent unauthorised access or
              disclosure, we have put in place suitable physical, electronic, and managerial procedures to safeguard and
              secure the information we collect online.
            </p>
            <h2>How we use cookies</h2>
            <p>
              A cookie is a small file which asks permission to be placed on your computer's hard drive. Once you agree,
              the file is added, and the cookie helps analyse web traffic or lets you know when you visit a particular
              site. Cookies allow web applications to respond to you as an individual. The web application can tailor
              its operations to your needs, likes and dislikes by gathering and remembering information about your
              preferences.
            </p>
            <p>
              We use traffic log cookies to identify which pages are being used. This helps us analyse data about web
              page traffic and improve our website in order to tailor it to customer needs. We only use this information
              for statistical analysis purposes and then the data is removed from the system.
            </p>
            <p>
              Overall, cookies help us provide you with a better website, by enabling us to monitor which pages you find
              useful and which you do not. A cookie in no way gives us access to your computer or any information about
              you, other than the data you choose to share with us.
            </p>
            <p>
              You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can
              usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking
              full advantage of the website.
            </p>
            <h2>Microsoft Clarity</h2>
            <p>
              We partner with Microsoft Clarity and Microsoft Advertising to capture how you use and interact with our
              website through behavioral metrics, heatmaps, and session replay to improve and market our
              products/services. Website usage data is captured using first and third-party cookies and other tracking
              technologies to determine the popularity of products/services and online activity. Additionally, we use
              this this this information for site optimization, fraud/security purposes, and advertising. For more
              information information Microsoft collects and uses your data, visit the{' '}
              <a className="text-link" href="https://privacy.microsoft.com/privacystatement">
                Microsoft Privacy Statement
              </a>
              .
            </p>
            <h2>Links to other websites</h2>
            <p>
              Our website may contain links to other websites of interest. However, once you have used these links to
              leave our site, you should note that we do not have any control over that other website. Therefore, we
              cannot be responsible for the protection and privacy of any information which you provide whilst visiting
              such sites and such sites are not governed by this privacy statement. You should exercise caution and look
              at the privacy statement applicable to the website in question.
            </p>
          </div>
        </Container>
      </Layout>
    </>
  );
};

export default Privacy;
