import React, { Dispatch, SetStateAction } from 'react';
import { Button, EpcGraph, Loading } from '@epcbuilder/lib/components';
import usePropertyEpc from '@epcbuilder/lib/hooks/properties/usePropertyEPC';
import { CreateAddress } from '@epcbuilder/lib/models/properties';
import { format } from 'date-fns';
import { FormStage } from '@/models/generic';

const EpcStage = ({
  address,
  handleBypassEpc,
  setFormStage,
}: {
  address: CreateAddress;
  handleBypassEpc: () => void;
  setFormStage: Dispatch<SetStateAction<FormStage>>;
}) => {
  const { epc, isLoading } = usePropertyEpc({ postcode: address.postcode, addressLine1: address.addressLine1 });

  if (isLoading) {
    return <Loading />;
  }

  if (!epc) {
    return (
      <div className="flex flex-col">
        <h1>Stage 3 of 4 - EPC Status</h1>
        <div className="mt-4 flex flex-col">
          <h2>We are unable to locate an EPC rating for address:</h2>
          <p>{address.addressLine1}</p>
          {address.addressLine2 && <p>{address.addressLine2}</p>}
          <p>{address.city}</p>
          <p>{address.postcode}</p>
          <p className="mt-4">This may be because:</p>
          <p>
            1. The property does not have an EPC,{' '}
            <button
              type="button"
              className="text-link"
              onClick={() => {
                handleBypassEpc();
                setFormStage(FormStage.USER);
              }}
            >
              click here to continue
            </button>
          </p>
          <p>2. You have recently had an EPC done, if so, please contact us on 0800 058 4140</p>
          <p>
            3. The address is incorrect,{' '}
            <button type="button" className="text-link" onClick={() => setFormStage(FormStage.POSTCODE)}>
              click here to edit your address
            </button>
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <h1>Stage 3 of 4 - EPC Status</h1>
      <div className="text-dark mt-4 flex flex-col gap-4">
        <div className="flex flex-col justify-between gap-2 sm:flex-row">
          <div className="flex flex-col">
            <p>{address.addressLine1}</p>
            {address.addressLine2 && <p>{address.addressLine2}</p>}
            <p>{address.city}</p>
            <p>{address.postcode}</p>
          </div>
          <div className="flex flex-col">
            <div className="flex flex-row items-center gap-2">
              <p>Date of inspection:</p>
              <p className="font-bold">
                {epc?.ratingCreatedOn && format(new Date(epc?.ratingCreatedOn), 'dd/MM/yyyy')}
              </p>
            </div>
            <div className="flex flex-row items-center gap-2">
              <p>Valid until:</p>
              <p
                className={`font-bold ${
                  epc?.certificateValidUntil && new Date() > new Date(epc?.certificateValidUntil) && 'text-error'
                }`}
              >
                {epc?.certificateValidUntil && format(new Date(epc?.certificateValidUntil), 'dd/MM/yyyy')}
              </p>
            </div>
            <div className="flex flex-row items-center gap-2">
              <p>Property type:</p>
              <p className="font-bold">{epc?.propertyType}</p>
            </div>
            <div className="flex flex-row items-center gap-2">
              <p>Built form:</p>
              <p className="font-bold">{epc?.builtForm}</p>
            </div>
          </div>
        </div>
        <div className="relative w-[300px]">{epc && <EpcGraph propertyRating={epc.currentRating} />}</div>
        <div className="flex flex-col gap-2">
          <Button onClick={() => setFormStage(FormStage.USER)}>Next</Button>
          <button type="button" className="text-link mt-4 text-center" onClick={() => setFormStage(FormStage.POSTCODE)}>
            Or edit your address
          </button>
        </div>
      </div>
    </div>
  );
};

export default EpcStage;
