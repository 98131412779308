import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Loading, SensitiveTextInput, TextInput } from '@epcbuilder/lib/components';
import useReferrerSourceCookie from '@epcbuilder/lib/hooks/useReferrerSourceCookie';
import { CreateAddress } from '@epcbuilder/lib/models/properties';
import { handleFormErrors, POSTCODE_REGEX } from '@epcbuilder/lib/utils';
import { AxiosErrorData, handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { EMAIL_REGEX, PHONE_REGEX } from '@epcbuilder/lib/utils/generic';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FormStage, RegisterWithAddress } from '@/models/generic';
import { postRegisterWithAddress } from '@/network/register';

const registerSchema = yup.object().shape({
  email: yup.string().matches(EMAIL_REGEX, 'Email is not a valid email address').required('Email must not be empty'),
  firstName: yup.string().required('First Name must not be empty'),
  lastName: yup.string().required('Last Name must not be empty'),
  phone: yup.string().matches(PHONE_REGEX, 'Phone is not a valid UK phone number').required('Phone must not be empty'),
  password: yup.string().required('Password must not be empty').min(6, 'Password must be at least 6 characters long'),
  confirmPassword: yup
    .string()
    .required('Confirm Password must not be empty')
    .oneOf([yup.ref('password'), ''], 'Confirm Password must match Password'),
  addressLine1: yup.string().required('Address Line 1 must not be empty'),
  addressLine2: yup.string().optional(),
  city: yup.string().required('City must not be empty'),
  postcode: yup.string().required().matches(POSTCODE_REGEX, 'Postcode is not a valid postcode'),
  bypassEPC: yup.boolean().optional(),
  ownerStatusId: yup.number().required().min(1, 'Owner Status must not be empty'),
});

const UserStage = ({
  onClose,
  address,
  setFormStage,
}: {
  onClose: () => void;
  address: CreateAddress;
  setFormStage: Dispatch<SetStateAction<FormStage>>;
}) => {
  const refSrcValue = useReferrerSourceCookie();

  const defaultValues: RegisterWithAddress = {
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    password: '',
    confirmPassword: '',
    referrerSource: refSrcValue,
    addressLine1: address.addressLine1,
    addressLine2: address.addressLine2,
    city: address.city,
    postcode: address.postcode,
    bypassEPC: address.bypassEPC,
    ownerStatusId: address.ownerStatusId,
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
  } = useForm<RegisterWithAddress>({
    defaultValues,
    resolver: yupResolver(registerSchema),
  });

  const onSubmit: SubmitHandler<RegisterWithAddress> = async (data: RegisterWithAddress) => {
    try {
      await postRegisterWithAddress({
        email: data.email.trim(),
        firstName: data.firstName,
        lastName: data.lastName,
        phone: data.phone.trim(),
        password: data.password,
        confirmPassword: data.confirmPassword,
        referrerSource: data.referrerSource,
        addressLine1: data.addressLine1,
        addressLine2: data.addressLine2,
        city: data.city,
        postcode: data.postcode.trim(),
        bypassEPC: data.bypassEPC,
        ownerStatusId: data.ownerStatusId,
      });
      toast.success('Registration successful. Please check your emails to confirm your email address');
      onClose();
    } catch (error: unknown) {
      const { errors } = error as AxiosErrorData;
      handleFormErrors<RegisterWithAddress>(setError, errors);
      handleUnknownDetail(error);
    }
  };

  useEffect(() => {
    if (errors.addressLine1 || errors.addressLine2 || errors.city || errors.postcode || errors.ownerStatusId) {
      toast.error('There seems to be an error, please go back and check your address');
    }
  }, [errors]);

  if (isSubmitting) {
    return <Loading />;
  }

  return (
    <>
      <h1>Stage 4 of 4 - Register with EPC Builder</h1>
      <p className="italic">
        Check eligibility for grants, book an energy assessment, view your Improvement Plan and much more.
      </p>
      <form className="mt-4 flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          {...register('email')}
          id="email"
          name="email"
          title="Your email address"
          placeholder="Email"
          error={errors.email?.message}
        />
        <TextInput
          {...register('firstName')}
          id="firstName"
          name="firstName"
          title="Your first name"
          placeholder="First name"
          error={errors.firstName?.message}
        />
        <TextInput
          {...register('lastName')}
          id="lastName"
          name="lastName"
          title="Your last name"
          placeholder="Last name"
          error={errors.lastName?.message}
        />
        <TextInput
          {...register('phone')}
          id="phone"
          name="phone"
          title="Your phone number"
          placeholder="Phone"
          error={errors.phone?.message}
        />
        <SensitiveTextInput
          {...register('password')}
          id="password"
          name="password"
          title="Your password"
          placeholder="Password"
          error={errors.password?.message}
        />
        <SensitiveTextInput
          {...register('confirmPassword')}
          id="confirmPassword"
          name="confirmPassword"
          title="Confirm your password"
          placeholder="Confirm Password"
          error={errors.confirmPassword?.message}
        />
        <div className="flex flex-row items-center">
          <p>
            By signing up you agree to our{' '}
            <Link className="text-link" to="privacy">
              Privacy Policy
            </Link>
          </p>
        </div>
        <Button loading={isSubmitting} type="submit">
          Register
        </Button>
      </form>
      <button type="button" className="text-link mt-4 text-center" onClick={() => setFormStage(FormStage.ADDRESS)}>
        Or go back
      </button>
    </>
  );
};

export default UserStage;
